@font-face {
    font-family: "GowunDodum-Regular";
    font-weight: normal;
    src: url("../fonts/english/Roboto_Mono/RobotoMono-VariableFont_wght.ttf") format("truetype");
}
@font-face {
    font-family: "KdamThmorPro-Regular";
    font-weight: normal;
    src: url("../fonts/Kdam_Thmor_Pro/KdamThmorPro-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "Maplestory OTF Light.otf";
    font-weight: normal;
    src: url("../fonts/MaplestoryFont_OTF/Maplestory\ OTF\ Light.otf") format("truetype");
}
body{
    margin:0px;
    background-color: rgb(76, 76, 247);
    height: 100vh;
}